import React from 'react'
import ReactDOM from 'react-dom'
import { Copyright, Link, Portal } from 'react-portal'
import imgSrcLogin from './assets/images/RightPicture.png'
import imgSrcLogo from './assets/images/Logo.png'
import './assets/scss/main.scss'

const Footer = () => (
  <>
    <p className='contact-us'>
      {'Contact Us: '}
      <Link url='mailto:pe-cgs@osumc.edu'>pe-cgs@osumc.edu</Link>
    </p>
    <p>
      <Copyright startYear={2021} />
      {' | '}
      <Link url='https://it.osu.edu/privacy'>Privacy Policy</Link>
    </p>
  </>
)
ReactDOM.render(
  <Portal
    categoryRenderer={() => null}
    footerRenderer={Footer}
    {...{
      applicationName: 'PE-CGS',
      imgSrcLogin,
      imgAltLogin: '',
      imgSrcLogo,
      imgAltLogo: 'Participant Engagement and Cancer Genome Sequencing'
    }}
  />,
  document.getElementById('root')
)
